<template>
	<div>
		<div class="banner_info" style="background: url(images/4_02.jpg) center no-repeat;background-size: cover;">
			<img src="images/4_03.png">
		</div>
		<div class="infopage">
			<div class="container">
				<certification />
			</div>
			<div class="container clearfix">
				<div class="left">
					<div class="box">
						<dl @click="gltx">
							<dt>{{name}}</dt>
							<dd>management system</dd>
						</dl>
						<ul>
							<li :class="{on:item.id == cId}" v-for="(item,index) in leftData" :key="item.id"
								@click="itemClick(item)"><a href="javascript:;">{{item.name}}</a></li>
						</ul>
					</div>


				</div>
				<div class="right">
					<div v-if="gltxStatus">
						<div class="title flex lg">
							<div class="flex_item"><b class="">{{name}}</b></div>
							<div class="bread">
								<a href="#">首页</a> > <a href="#">{{name}}</a> > <span>首页</span>
							</div>
						</div>
						<div class="newsitem">
							<div class="glxt-box">
								<div v-for="(item,index) in leftData" :key="item.id">
									<div class="glxt-item-box">
										<a><img class="glxt-itme-pic" :src="srclist[index]" @click="itemClick(item)"/></a>
										<a><div class="glxt-itme-title" @click="itemClick(item)">{{item.name}}</div></a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-else>
						<div class="title flex lg">
							<div class="flex_item"><b class="">{{cItem.name}}</b></div>
							<div class="bread">
								<a href="#">首页</a> > <a href="#">{{name}}</a> > <span>{{cItem.name}}</span>
							</div>
						</div>
						<div class="newsitem" v-html="articleDetail">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapActions,
		mapState
	} from "vuex";
  import certification from "@/components/certification";
	export default {
		name: "management",
    components:{
      certification
    },
		data() {
			return {
				srclist:[
					require( '../../public/images/glxt_01.png'),
					require( '../../public/images/glxt_02.png'),
					require( '../../public/images/glxt_03.png'),
					require( '../../public/images/glxt_04.png'),
					require( '../../public/images/glxt_05.png'),
					require( '../../public/images/glxt_06.png'),
				],
				png:'.png',
				pid: 0,
				id: 0,
				index: 0,
				cId: 0,
				columnData: [],
				articleDetail: '',
				cItem: '',
				leftData: [],
				gltxStatus: true,
        name:''
			}
		},
		mounted() {
			let route = this.$route.query
			this.pid = route?.pid
			this.id = route?.id
			this.index = route?.index
      this.name = route?.name
			console.log(this.id)
			this.$api.getChildColumn({
				columnId: this.pid
			}).then(res => {
				this.leftData = res.data.data;
				this.$nextTick(function() {
					this.cId = this.leftData[this.index].id
					this.cItem = this.leftData[this.index];
					let articleId = this.leftData[this.index]?.articleList[0]?.id;
					this.getArticleData(articleId)
				})
			})

		},
		methods: {
			...mapActions(['columnAction']),
			itemClick(item) {
				console.log(item, 'item');
				this.cId = item.id
				this.cItem = item;
				let articleId = item?.articleList[0]?.id;
				this.getArticleData(articleId);
				this.gltxStatus = false;
			},
			gltx(){
				this.gltxStatus = true;
			},
			async findColumnData() {
				let res = await this.$api.getChildColumn({
					columnId: this.cId
				});
				this.columnData = res.data.data;
				console.log(this.columnData, '--');
			},
			async getArticleData(articleId) {
				let res = await this.$api.getArticleData({
					id: articleId
				});
				this.articleDetail = res.data.data?.content;
			},
		},
		computed: {
			...mapState(["cacheColumn"]),
		}
	}
</script>

<style scoped>

</style>
